@use "./../../vars/vars.scss" as *;

.dropShadow {
  filter: drop-shadow(0px 0px 0px var(--upTech_blue));
  transition: 0.2s ease-in-out;
  &:hover {
    transition: 0.2s ease-in-out;
    filter: drop-shadow(5px 5px 1px var(--upTech_blue));
    & .animation-container {
      transition: 0.2s ease-in-out;
      transform: translate(-5px, -5px);
    }
  }
  &:not(:hover) {
    transition: 0.2s ease-in-out;
    filter: drop-shadow(0px 0px 0px var(--upTech_blue));
    & .animation-container {
      transition: 0.2s ease-in-out;
      transform: translate(0px, 0px);
    }
  }
}
.dropShadowToggleActive {
  transition: 0.2s ease-in-out;
  filter: drop-shadow(5px 5px 1px var(--upTech_blue));
  & .animation-container-active-toggle {
    transition: 0.2s ease-in-out;
    transform: translate(-5px, -5px);
  }
}
.dropShadowToggle {
  transition: 0.2s ease-in-out;
  filter: drop-shadow(0px 0px 0px var(--upTech_blue));
  & .animation-container-toggle {
    transition: 0.2s ease-in-out;
    transform: translate(0px, 0px);
  }
}

.small-cutted-button {
  width: 100px !important;
  height: 25px;
  transition: 0.2s all;
  border-radius: 0 !important;
  border: 0 !important;
  clip-path: polygon(
    0 0,
    calc(100% - var(--buttons-edge-size)) 0,
    100% var(--buttons-edge-size),
    100% 100%,
    var(--buttons-edge-size) 100%,
    0 calc(100% - var(--buttons-edge-size))
  );
  & .light {
    background: var(--light) !important;
    color: var(--dark) !important;
  }
  & .dark {
    background: var(--dark) !important;
    color: var(--light) !important;
  }
  & .blue {
    background: var(--upTech_blue) !important;
    color: var(--light) !important;
  }
}

.simple-button-light {
  background: var(--light) !important;
  color: var(--dark) !important;
}
.simple-button-dark {
  background: var(--dark) !important;
  color: var(--light) !important;
}
.simple-button-blue {
  background: var(--upTech_blue) !important;
  color: var(--light) !important;
}
.simple-button-primary {
  background: var(--upTech_blue) !important;
  color: var(--light) !important;
}

.large-cutted-button {
  width: 140px;
  height: 45px;
  transition: 0.2s all;
  border-radius: 0 !important;
  border: 0 !important;
  clip-path: polygon(
    0 0,
    calc(100% - var(--buttons-edge-size)) 0,
    100% var(--buttons-edge-size),
    100% 100%,
    var(--buttons-edge-size) 100%,
    0 calc(100% - var(--buttons-edge-size))
  );
  & .light {
    background: var(--light) !important;
    color: var(--dark) !important;
  }
  & .dark {
    background: var(--dark) !important;
    color: var(--light) !important;
  }
  & .blue {
    background: var(--upTech_blue) !important;
    color: var(--light) !important;
  }
}

.path-button {
  clip-path: polygon(
    0 0,
    calc(100% - var(--buttons-edge-size)) 0,
    100% var(--buttons-edge-size),
    100% 100%,
    var(--buttons-edge-size) 100%,
    0 calc(100% - var(--buttons-edge-size))
  );
}

.patternSection {
  background-image: url("./../../../img/icons/boxPattern.svg");
  & .patternSectionOverlayDark {
    background-color: var(--dark_Rgba07);
  }
  & .patternSectionOverlayBlue {
    background-color: var(--upTech_blue_Rgba);
  }
}

.transparent-separator {
  & .transparent-separator-arrows-right {
    transform: scaleX(-1);
    height: 20px;
  }
  & .transparent-separator-arrows-left {
    height: 20px;
  }
  & .transparent-separator-text {
    color: var(--light);
    text-transform: uppercase;
  }
}

.blue-separator {
  background-color: var(--upTech_blue);
  & .blue-separator-arrows-left {
    height: 15px;
  }
  & .blue-separator-arrows-down {
    height: 20px;
  }
  & .blue-separator-text {
    color: var(--light);
  }
}

.dots-text {
  & .dots-text-icon svg {
    height: 70%;
  }
  & .dots-text-title {
    color: var(--light);
    text-transform: uppercase;
  }
}
.dots-text-large {
  & .dots-text-icon svg {
    height: 70%;
  }
  & .dots-text-title-large {
    color: var(--light);
    text-transform: uppercase;
    margin-left: 10px;
  }
}
.bars-button {
  & .bars-button-icon {
    width: 70px;
    margin-left: 15px;
  }
}

.upTechCheckBox:checked, .upTechCheckBox:not(:checked){
  background-color: var(--upTech_black) !important;
}

.cutted-cards {
  clip-path: polygon(
    0 0,
    calc(100% - var(--cards-edge-size)) 0,
    100% var(--cards-edge-size),
    100% 100%,
    var(--cards-edge-size) 100%,
    0 calc(100% - var(--cards-edge-size))
  );
}

.cutted-cards-small {
  clip-path: polygon(
    0 0,
    calc(100% - var(--cards-edge-size-small)) 0,
    100% var(--cards-edge-size-small),
    100% 100%,
    var(--cards-edge-size-small) 100%,
    0 calc(100% - var(--cards-edge-size-small))
  );
}

.cutted-horizontalPc-cards {
  clip-path: polygon(
    0 0,
    calc(100% - var(--cards-edge-size)) 0,
    100% var(--cards-edge-size),
    100% 100%,
    var(--cards-edge-size) 100%,
    0 calc(100% - var(--cards-edge-size))
  );
}

.search-computer {
  & .search-computer-budget-counters {
    height: 45px;
    background: var(--light) !important;
    color: var(--dark) !important;
    font-size: var(--text-7) !important;
    transition: 0.2s all;
    border-radius: 0 !important;
    border: 0 !important;
    clip-path: polygon(
      0 0,
      calc(100% - var(--buttons-edge-size)) 0,
      100% var(--buttons-edge-size),
      100% 100%,
      var(--buttons-edge-size) 100%,
      0 calc(100% - var(--buttons-edge-size))
    );
    & .search-computer-budget-counters-arrow-left {
      transform: rotate(90deg);
      width: 80%;
    }
    & .search-computer-budget-counters-arrow-rigth {
      transform: rotate(-90deg);
      width: 80%;
    }
  }
  & .search-computer-text {
    color: var(--light);
  }
}

.game-cards {
  clip-path: polygon(
    0 0,
    calc(100% - var(--buttons-edge-size)) 0,
    100% var(--buttons-edge-size),
    100% 100%,
    var(--buttons-edge-size) 100%,
    0 calc(100% - var(--buttons-edge-size))
  );
  & .game-cards-image-container {
    & img {
      width: auto;
      height: 70px;
    }
    & div {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: fit-content;
      background-color: var(--upTech_black_Rgba);
      color: var(--light);
      font-size: var(--text-8);
      padding: 2px 0;
    }
  }
}

.blue-line {
  background-color: var(--upTech_blue);
}

.cutted-horizontalPc-cards {
  & .cutted-horizontalPc-cards-pc-info {
    background-color: var(--light);

    & .cutted-horizontalPc-cards-pc-info-title {
      padding-left: 44px;
    }

    & .cutted-horizontalPc-cards-pc-info-component-icon {
      height: 32.5px;
      min-width: 45px;
    }
  }
  & .cutted-horizontalPc-cards-pc-buy-bestDeal {
    background: var(--upTech_blue);
    & .cutted-horizontalPc-cards-pc-buy-delivery {
      font-size: var(--text-10);
      color: var(--light);
      font-weight: 300;
      & a {
        font-weight: 400;
      }
    }
    & .cutted-horizontalPc-cards-pc-buy-price-container {
      background: var(--upTech_blue_gradient);
    }
    & .cutted-horizontalPc-cards-pc-buy-image img {
      width: 100%;
    }
    & .cutted-horizontalPc-cards-pc-buy-price-old {
      text-decoration: line-through;
    }
    & .cutted-horizontalPc-cards-pc-buy-price-new {
      color: var(--light);
    }
    & .cutted-horizontalPc-cards-pc-buy-price-save {
      color: var(--light);
      text-transform: uppercase;
    }
    & .cutted-horizontalPc-cards-pc-buy-now {
      color: var(--light);
    }
  }
  & .cutted-horizontalPc-cards-pc-buy {
    background: var(--upTech_dark_gradient);
    & .cutted-horizontalPc-cards-pc-buy-delivery {
      font-size: var(--text-10);
      color: var(--light);
      font-weight: 300;
      & a {
        font-weight: 400;
      }
    }
    & .cutted-horizontalPc-cards-pc-buy-image img {
      width: 100%;
    }
    & .cutted-horizontalPc-cards-pc-buy-price-new {
      font-size: var(--text-2);
      color: var(--light);
    }
    & .cutted-horizontalPc-cards-pc-buy-price-stock {
      color: var(--upTech_grey);
      font-size: var(--text-7);
      text-transform: uppercase;
    }
    & .cutted-horizontalPc-cards-pc-buy-details {
      color: var(--light);
      font-size: var(--text-7);
      font-weight: 100;
    }
  }
}

.form-check-input {
  background-color: transparent !important;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid var(--light) !important;
  border-radius: 1px !important;
  box-shadow: none !important;
}

.arrowsText {
  & .arrowsText-doubleArrows {
    transform: rotate(90deg);
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.dropdown {
  padding: 0 !important;
  & button {
    width: 100%;
  }
  & .dropdown-menu {
    width: 100%;
  }
}
