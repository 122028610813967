@use "./../vars/vars.scss" as *;

.left-login:hover {
  .UpTechLogoLogin {
    transition: 0.5s all !important;
    transition-delay: 0.2s !important;
    transition-property: margin-top !important;
    margin-top: -220px !important;
  }

  .logoAnim{
    transition: 0.5s all !important;
    animation: 1s forwards logoAnimIN;
  }

  .hideCardLogin {
    transition: 0.5s all !important;
    transition-delay: 0.4s !important;
    transition-property: margin-top !important;
    margin-top: 200px !important;
    & span {
      transition: 0.5s all;
      opacity: 1;
      color: var(--light);
      transition-delay: 0.4s !important;
      transition-property: opacity !important;
    }
  }
}

.left-login {
  .UpTechLogoLogin {
    margin-top: 0px !important;
    transition: 0.5s all !important;
  }

  .logoAnim{
    transition: 0.5s all !important;
    transform: scale(1);
  }

  .hideCardLogin {
    margin-top: 0px !important;
    transition: 0.5s all !important;
    & span {
      transition: 0.5s all;
      color: var(--light);
      opacity: 0;
    }
  }
}

@keyframes logoAnimIN {

  0%{
    transform: scale(1);
  }

  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }

}
