@use "./layout/layout.scss" as *;
@use "./components/navbar/navbar.scss" as *;
@use "./components/footer/footer.scss" as *;
@use "./components/common/common.scss" as *;
@use "./pages/pages.scss" as *;
@use "vars/vars.scss" as *;

* {
  font-family: var(--body-font-family) !important;
}

input:focus{
  box-shadow: 0 0 0 rgb(255, 255, 255) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus{
    transition: background-color 600000s 0s, color 600000s 0s;
    font-family: var(--body-font-family) !important;
}
input:-webkit-autofill:disabled{

  font-family: var(--body-font-family) !important;
}
input[data-autocompleted] {
    background-color: transparent !important;
    color:transparent !important;
    font-family: var(--body-font-family) !important;
}

input:-internal-autofill-selected {
  color: red !important;
}

.h-fit-content {
  height: fit-content !important;
}
.w-fit-content {
  width: fit-content !important;
}
.min-width-0 {
  min-width: 0 !important;
}
.pointer {
  cursor: pointer !important;
}
.weight-100 {
  font-weight: 100 !important;
}
.weight-300 {
  font-weight: 300 !important;
}
.weight-400 {
  font-weight: 400 !important;
}
.weight-500 {
  font-weight: 500 !important;
}
.weight-600 {
  font-weight: 600 !important;
}
.weight-700 {
  font-weight: 700 !important;
}
.text-10 {
  font-size: 0.7rem !important;
}
.text-9 {
  font-size: 0.75rem !important;
}
.text-8 {
  font-size: 0.8rem !important;
}
.text-7 {
  font-size: 0.95rem !important;
}
.text-6 {
  font-size: 1.05rem !important;
}
.text-5 {
  font-size: 1.1rem !important;
}
.text-4 {
  font-size: 1.2rem !important;
}
.text-3 {
  font-size: 1.25rem !important;
}
.text-2 {
  font-size: 1.65rem !important;
}
.text-1 {
  font-size: 1.9rem !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.truncate {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.underline{ 
  text-decoration: underline !important;
}
.hover:hover {
  text-decoration: underline !important;
}
.hover-scale {
  transition: 0.1s all;
  &:hover {
    transform: scale(1.1);
    transition: 0.1s all;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.custom-modal .modal-content {
  background-color: var(--upTech_black);
  border-radius: 0 !important;
  clip-path: polygon(
    0 0,
    calc(100% - var(--modals-edge-size)) 0,
    100% var(--modals-edge-size),
    100% 100%,
    var(--modals-edge-size) 100%,
    0 calc(100% - var(--modals-edge-size))
  );
}

.small-cut {
  clip-path: polygon(
    0 0,
    calc(100% - var(--buttons-edge-size)) 0,
    100% var(--buttons-edge-size),
    100% 100%,
    var(--buttons-edge-size) 100%,
    0 calc(100% - var(--buttons-edge-size))
  );
}
