@use "./../vars/vars.scss" as *;

.home-image-slider-container {
  & .home-image-slider-image {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      height: 500px;
    }
    @media screen and (min-width: 576px) and (max-width: 991px) {
      height: 400px;
    }
    @media only screen and (min-width: 1200px) {
      height: 600px;
    }
    @media only screen and (max-width: 575px) {
      height: 250px;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
  & .home-image-slider-bar-container {
    background-color: var(--upTech_black);
    transition: 0.2s all;
    & .home-image-slider-bar-container-bar {
      background-color: var(--upTech_blue);
      width: 10px;
      transition: 0.2s all;
    }
  }
  & .home-image-slider-buttons-container {
    background-color: var(--dark);
    transition: 0.2s all;

    & .home-image-slider-buttons div {
      color: var(--light);
      transition: 0.2s all;
    }

    & .home-image-slider-buttons-active {
      color: var(--upTech_blue);
      transition: 0.2s all;
      transform: rotate(135deg);
    }
  }
  & .rtds-single-slide-styles div {
    padding: 0 !important;
  }
}

.home-banner {
  background: url("https://images.unsplash.com/photo-1661961110671-77b71b929d52?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & .home-banner-overlay {
    background-color: var(--upTech_blue_Rgba);
    color: var(--light);
  }
}

.swiper-slide {
  /* Center slide text vertically */
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .card-slider-content-card {
    background-color: var(--light);
    & .computer-name {
      & span:nth-child(2) {
        margin-left: 5px;
        color: var(--upTech_blue);
      }
    }
    & .computer-price {
      background: var(--upTech_dark_gradient);
      line-height: 1;
      & .computer-price-image img {
        height: 130px !important;
      }
      & .computer-price-old {
        font-size: var(--text-7);
        color: var(--upTech_blue);
        text-decoration: line-through;
      }
      & .computer-price-new {
        font-size: var(--text-2);
        color: var(--light);
      }
      & .computer-price-arrows svg {
        width: 70px;
      }
      & .computer-price-buy {
        color: var(--light);
      }
    }
    & .computer-price-bestDeal {
      background: var(--upTech_blue_gradient);
      line-height: 1;
      & .computer-price-image img {
        height: 130px !important;
      }
      & .computer-price-old {
        color: var(--upTech_black);
        text-decoration: line-through;
      }
      & .computer-price-new {
        color: var(--light);
      }
      & .computer-price-arrows svg {
        width: 70px;
      }
      & .computer-price-buy {
        font-size: var(--text-5);
        text-transform: uppercase;
        color: var(--light);
      }
    }
    & .computer-shipping {
      color: var(--upTech_blue);
      line-height: 1;
      height: fit-content;

      & .best-deal {
        color: var(--light);
        padding: 7.5px;
        background-color: var(--upTech_blue);
        margin-left: 10px;
      }
    }
    & .computer-components {
      & .computer-components-icon {
        & svg {
          margin: 0 10px;
          height: 13px;
          width: 16px;
        }
      }
    }
  }
}
.card-slider-right-arrow-container {
  width: fit-content;
  height: fit-content;
  & svg {
    width: 35px;
    transform: scaleX(-1);
    cursor: pointer;
  }
}
.card-slider-left-arrow-container {
  width: fit-content;
  height: fit-content;
  & svg {
    width: 35px;
    cursor: pointer;
  }
}
