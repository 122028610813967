@use "./../vars/vars.scss" as *;

.benchmarks-bars{
  clip-path: polygon(
    0 0,
    calc(100% - 0.15em) 0,
    100% 0.15em,
    100% 100%,
    0.15em 100%,
    0 calc(100% - 0.15em)
  );
  background-color: var(--upTech_green);
}