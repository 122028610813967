  // COLORS

:root[data-theme=lightTheme]{
  // Body
  --body-font-family: 'Oswald', sans-serif;

  //GLOBAL COLORS
--upTech_black: #1A1A1A;
--upTech_semi_black: #151515;
--dark: #000000;
--light: #ffffff;
--warning:#FF8000;
--danger:#dc3545;
--success:#28a745;
--upTech_green: #B8FF3B;
--upTech_blue:#1C47D1;
--upTech_blue_Rgba:rgba(28, 71, 209, 0.8);
--upTech_black_Rgba:rgba(26, 26, 26, 0.9);
--dark_Rgba07: rgba(0, 0, 0, 0.7);
--dark_Rgba08: rgba(0, 0, 0, 0.9);
--upTech_grey:#9FA3AA;
--upTech_dark_gradient: linear-gradient(to right, var(--upTech_black), var(--dark));
--upTech_blue_gradient: linear-gradient(to right, var(--upTech_blue), #193CAB);
--dark_gradient:linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%);
--semi_dark_gradient: linear-gradient(to right, var(--upTech_semi_black), transparent);

  //TEXT-SIZE
--text-10: 0.7rem;
--text-9: 0.75rem;
--text-8: 0.8rem;
--text-7: 0.95rem;
--text-6: 1.05rem;
--text-5: 1.10rem;
--text-4: 1.2rem;
--text-3: 1.25rem;
--text-2: 1.5rem;
--text-1: 1.7rem;

  //CUT SIZES
--buttons-edge-size: 0.5em;
--cards-edge-size: 1.5em;
--modals-edge-size: 1.0em;

  
}

:root[data-theme=darkTheme]{

    // Body
    --body-font-family: 'Oswald', sans-serif;

    //GLOBAL COLORS
  --upTech_black: #1A1A1A;
  --upTech_semi_black: #151515;
  --dark: #000000;
  --light: #ffffff;
  --warning:#FF8000;
  --danger:#dc3545;
  --success:#28a745;
  --upTech_green: #B8FF3B;
  --upTech_blue:#1C47D1;
  --upTech_blue_Rgba:rgba(28, 71, 209, 0.8);
  --upTech_black_Rgba:rgba(26, 26, 26, 0.9);
  --dark_Rgba07: rgba(0, 0, 0, 0.7);
  --dark_Rgba08: rgba(0, 0, 0, 0.9);
  --upTech_grey:#9FA3AA;
  --upTech_dark_grey:#272828;
  --upTech_dark_gradient: linear-gradient(to right, var(--upTech_black), var(--dark));
  --upTech_blue_gradient: linear-gradient(to right, var(--upTech_blue), #193CAB);
  --dark_gradient:linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%);
  --semi_dark_gradient: linear-gradient(to right, var(--upTech_semi_black), transparent);

    //TEXT-SIZE
  --text-10: 0.7rem;
  --text-9: 0.75rem;
  --text-8: 0.8rem;
  --text-7: 0.95rem;
  --text-6: 1.05rem;
  --text-5: 1.10rem;
  --text-4: 1.2rem;
  --text-3: 1.25rem;
  --text-2: 1.5rem;
  --text-1: 1.7rem;

    //CUT SIZES
  --buttons-edge-size: 0.5em;
  --cards-edge-size: 1.5em;
  --cards-edge-size-small: 1.0em;
  --modals-edge-size: 1.0em;
  
    
}



