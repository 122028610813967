@use './../vars/vars.scss' as *;

.appLayout{
  background-color: var(--upTech_black);
  font-family: var(--body-font-family) !important;
  letter-spacing:0.05em;
  color: var(--upTech_black);

  .home{
    background-color: var(--upTech_black);
  }
}