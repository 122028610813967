@use "./../vars/vars.scss" as *;

.accordion {
  border: 0;
  & .accordion-item {
    background-color: transparent;
    border: 0;
    color: var(--light);
    & .accordion-header {
      & .accordion-button {
        background-color: transparent;
        border-radius: 0 !important;
        box-shadow: none;
        padding:0;
        color: var(--light);
        &:not(.collapsed) {
          color: var(--light);

        }
        &::after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
      }
    }
  }
  .accordion-body {
    padding: 10px 0 !important;
  }
}

.slider {
  cursor: pointer;
  width: 95%;
}

@media only screen and (max-width: 767px) {
  .slider {
    cursor: pointer;
    width: 98%;
  }
}

.track {
  background-color: var(--light);
  height: 2px;
  border: 0;
}

.track:nth-child(2) {
  background-color: var(--light);
}

.thumb {
  width: 13px;
  height: 13px;
  background: var(--dark);
  border: 1px solid var(--light);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  outline: none;
}

.perfect-pc-filter{
 & .perfect-pc-filter-pagination-container{
   & .pagination-position {
      border: 1px solid var(--light);
      background: transparent;
      color: var(--light);
      width: 22px;
      height: 22px;
   }
   & .pagination-position-active{
    border: 1px solid var(--light);
    background: var(--light);
    color: var(--dark);
    width: 22px;
    height: 22px;
   }
 }
}

