@use './../../vars/vars.scss' as *;


.footer{
  margin-top: 100px !important;
  background-color: var(--dark);
  & .footer-column-list{
    color: var(--light);
  }
}
.reserved-rights{
  color: var(--light);
}